export function createShader(
	gl: WebGLRenderingContext,
	sourceCode: string,
	type: GLenum,
): WebGLShader {
	// gl.VERTEX_SHADER または gl.FRAGMENT_SHADER のどちらかをコンパイル
	const shader = gl.createShader(type);
	if (!shader) {
		throw "WebGL シェーダを作成できませんでした。";
	}
	gl.shaderSource(shader, sourceCode);
	gl.compileShader(shader);

	if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
		const info = gl.getShaderInfoLog(shader);
		throw `WebGL プログラムをコンパイルできませんでした。\n\n${info}`;
	}
	return shader;
}
